import { relations } from 'drizzle-orm';
import { integer, sqliteTable, text, unique } from 'drizzle-orm/sqlite-core';

import type { AvailableProviders } from '../helpers';
import { advertisersToTokens } from './advertisers-to-tokens';
import { organizationsToAdvertisers } from './organizations-to-advertisers';

export const advertisers = sqliteTable(
	'advertisers',
	{
		id: text('id').primaryKey().$type<`acc_${string}`>(),
		name: text('name').notNull(),
		status: text('status').$type<'ok' | 'ko'>().notNull(),

		provider: text('provider').$type<AvailableProviders>().notNull(),

		accountId: text('account_id').notNull(),

		createdAt: integer('created_at', { mode: 'number' }).notNull(),
		updatedAt: integer('updated_at', { mode: 'number' }),
		deletedAt: integer('deleted_at', { mode: 'number' }),
	},
	(table) => ({
		accountIdUnique: unique('account_id_unique').on(table.provider, table.accountId),
	}),
);

export const advertiserRelations = relations(advertisers, ({ many }) => ({
	advertisersToTokens: many(advertisersToTokens),
	organizationsToAdvertisers: many(organizationsToAdvertisers),
}));
