import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { redirect } from '@tanstack/react-router';
import { objectify } from 'radash';
import { useEffect, useMemo } from 'react';
import Variation from '#app/components/variation/Variation.js';
import usePlacement from '#app/hooks/use-placement.ts';
import { getCreativeQueryOptions } from '#app/queries/creative.js';
import { useCreativeNavbarContext } from '../contexts';
import Attribution from './components/Attribution';
import Versions from './components/version/Versions';

export const Route = createFileRoute(
	'/_dashboard/campaigns/$campaignId/creatives/$creativeId/assets',
)({
	component: RouteComponent,
	staleTime: 0,
	validateSearch: (search): { version?: `ver_${string}` | undefined } => ({
		version: search.version as `ver_${string}` | undefined,
	}),
	loaderDeps: ({ search: { version } }) => ({ version }),
	loader: async ({ params, deps, context, preload }) => {
		const creative = await context.queryClient.fetchQuery(
			getCreativeQueryOptions(params.creativeId),
		);

		const firstVersion = creative.versions.sort((a, b) => a.createdAt - b.createdAt).at(0);

		const isInCreative = creative.versions.some((version) => version.id === deps.version);

		if ((!deps.version || !isInCreative) && firstVersion && !preload) {
			throw redirect({
				to: '/campaigns/$campaignId/creatives/$creativeId/assets',
				params,
				search: {
					version: firstVersion.id,
				},
			});
		}

		return creative;
	},
});

function RouteComponent() {
	const { creativeId } = Route.useParams();
	const { version: versionId } = Route.useLoaderDeps();
	const { placementsById } = usePlacement();
	const { data: creative } = useSuspenseQuery(getCreativeQueryOptions(creativeId));
	const { setMenu } = useCreativeNavbarContext();

	const versionById = useMemo(
		() => objectify(creative.versions, ({ id }) => id),
		[creative.versions],
	);

	const version = useMemo(
		(): (typeof creative.versions)[number] | undefined => versionId && versionById[versionId],
		[versionId, creativeId, versionById],
	);

	useEffect(() => {
		setMenu(<Versions creativeId={creativeId as `crea_${string}`} versions={creative.versions} />);
	}, [creativeId]);

	return (
		<>
			{version && (
				<Attribution className='m-3 min-h-[90vh] p-3' creative={creative} version={version}>
					<div className='grid 3xl:grid-cols-4 3xl:grid-cols-5 grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3'>
						{creative.variations.map((variation) => (
							<Variation
								placement={
									variation.placementId ? placementsById[variation.placementId] : undefined
								}
								key={variation.id}
								variation={variation}
								version={version}
								assetAssignments={version.assetAssignments.filter(
									({ variationId }) => variation.id === variationId,
								)}
							/>
						))}
					</div>
				</Attribution>
			)}
		</>
	);
}
