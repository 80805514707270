import { relations } from 'drizzle-orm';
import { integer, primaryKey, sqliteTable, text } from 'drizzle-orm/sqlite-core';

import { advertisers } from './advertisers';

export const organizationsToAdvertisers = sqliteTable(
	'organizations_to_advertisers',
	{
		advertiserId: text('advertiser_id')
			.references(() => advertisers.id, { onDelete: 'cascade' })
			.$type<`acc_${string}`>()
			.notNull(),

		organizationId: text('organization_id').$type<`org_${string}`>().notNull(),
		createdAt: integer('created_at', { mode: 'number' }).notNull(),
	},
	(table) => ({
		pk: primaryKey({ columns: [table.advertiserId, table.organizationId] }),
	}),
);

export const organizationsToAdvertisersRelations = relations(
	organizationsToAdvertisers,
	({ one }) => ({
		advertiser: one(advertisers, {
			fields: [organizationsToAdvertisers.advertiserId],
			references: [advertisers.id],
		}),
	}),
);
