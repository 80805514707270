import type { Placement } from '@adframe/database-adframe/types';
import { queryOptions } from '@tanstack/react-query';
import getApiClient from '#app/services/internal-api/get-api-client.ts';

export const placementsQueryOptions = queryOptions({
	queryKey: ['placements'],
	initialData: [],
	queryFn: () =>
		getApiClient()
			.placements.$get()
			.then((result) => result.json() as Promise<Placement[]>),
});
