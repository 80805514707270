import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { InferRequestType } from 'hono';

import type { TokenWithAdvertisers } from '@adframe/database-integration/types';
import getIntegrationClient from '../services/internal-api/get-integration-client';

export const advertisersQueryOptions = queryOptions({
	queryKey: ['advertisers'],
	initialData: [],
	select: (data) => data.filter(({ deletedAt }) => !deletedAt),
	queryFn: async () =>
		getIntegrationClient()
			.advertisers.$get({ query: {} })
			.then((result) => result.json()),
});

export const tokensQueryOptions = queryOptions({
	queryKey: ['tokens'],
	initialData: [],
	select: (data) => data.filter(({ deletedAt }) => !deletedAt),
	queryFn: async () =>
		getIntegrationClient()
			.tokens.$get({ query: {} })
			.then((result): Promise<TokenWithAdvertisers[]> => result.json()),
});

export default function useIntegration() {
	const client = getIntegrationClient();
	const queryClient = useQueryClient();

	const { data: advertisers } = useQuery(advertisersQueryOptions);

	const { data: tokens } = useQuery(tokensQueryOptions);

	const exchange = useMutation({
		mutationFn: async (request: InferRequestType<(typeof client.exchange)[':provider']['$post']>) =>
			client.exchange[':provider'].$post(request).then((result) => result.json()),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['tokens'],
			});

			queryClient.invalidateQueries({
				queryKey: ['advertisers'],
			});
		},
	});

	const deleteToken = useMutation({
		mutationFn: async (
			request: InferRequestType<(typeof client)['tokens'][':tokenId']['$delete']>,
		) => client.tokens[':tokenId'].$delete(request).then((result) => result.json()),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['tokens'],
			});

			queryClient.invalidateQueries({
				queryKey: ['advertisers'],
			});
		},
	});

	return {
		advertisers,
		tokens,
		deleteToken,
		exchange,
	};
}
