import { relations } from 'drizzle-orm';
import { index, integer, sqliteTable, text, unique } from 'drizzle-orm/sqlite-core';

import type { AvailableProviders } from '../helpers';
import { advertisersToTokens } from './advertisers-to-tokens';

export const tokens = sqliteTable(
	'tokens',
	{
		id: text('id').primaryKey().$type<`tkn_${string}`>(),

		token: text('token').notNull(),

		userId: text('user_id').$type<`usr_${string}`>().notNull(),
		organizationId: text('organization_id').$type<`org_${string}`>().notNull(),
		provider: text('provider').$type<AvailableProviders>().notNull(),
		providerUserId: text('provider_user_id').notNull(),

		data: text('data', { mode: 'json' }),
		scopes: text('scopes', { mode: 'json' }).$type<string[]>().notNull(),

		invalidAt: integer('invalid_at', { mode: 'number' }),
		expiresAt: integer('expires_at', { mode: 'number' }),
		createdAt: integer('created_at', { mode: 'number' }).notNull(),
		updatedAt: integer('updated_at', { mode: 'number' }),
		deletedAt: integer('deleted_at', { mode: 'number' }),
	},
	(table) => ({
		userIndex: index('token_user_idx').on(table.userId),
		organizationIndex: index('token_organization_idx').on(table.organizationId),
		providerUserIdUnique: unique('provider_user_id_unique').on(
			table.provider,
			table.providerUserId,
			table.organizationId,
		),
		tokenUnique: unique('token_unique').on(table.token, table.organizationId),
	}),
);

export const tokenRelations = relations(tokens, ({ many }) => ({
	advertisersToTokens: many(advertisersToTokens),
}));
