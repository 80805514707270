/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as signLayoutImport } from './routes/sign/_layout'
import { Route as dashboardLayoutImport } from './routes/dashboard/_layout'
import { Route as dashboardMeIndexImport } from './routes/dashboard/me/index'
import { Route as dashboardCampaignsIndexImport } from './routes/dashboard/campaigns/index'
import { Route as signIndexImport } from './routes/sign/index'
import { Route as dashboardIndexImport } from './routes/dashboard/index'
import { Route as signUpImport } from './routes/sign/up'
import { Route as signOutImport } from './routes/sign/out'
import { Route as signHandleImport } from './routes/sign/handle'
import { Route as signConfirmImport } from './routes/sign/confirm'
import { Route as dashboardSettingsLayoutImport } from './routes/dashboard/settings/_layout'
import { Route as dashboardPlacementsLayoutImport } from './routes/dashboard/placements/_layout'
import { Route as dashboardAttributesLayoutImport } from './routes/dashboard/attributes/_layout'
import { Route as dashboardCampaignsCampaignIdIndexImport } from './routes/dashboard/campaigns/$campaignId/index'
import { Route as dashboardPlacementsIndexImport } from './routes/dashboard/placements/index'
import { Route as dashboardAttributesIndexImport } from './routes/dashboard/attributes/index'
import { Route as signInvitationsinvitationIdImport } from './routes/sign/invitations.$invitationId'
import { Route as dashboardSettingsPreferenceImport } from './routes/dashboard/settings/preference'
import { Route as dashboardSettingsPeopleImport } from './routes/dashboard/settings/people'
import { Route as dashboardSettingsGeneralImport } from './routes/dashboard/settings/general'
import { Route as dashboardPlacementsGroupsImport } from './routes/dashboard/placements/groups'
import { Route as dashboardCampaignsCampaignIdCreativesIndexImport } from './routes/dashboard/campaigns/$campaignId/creatives/index'
import { Route as dashboardSettingsIntegrationIndexImport } from './routes/dashboard/settings/integration/index'
import { Route as dashboardSettingsIntegrationHandleImport } from './routes/dashboard/settings/integration/handle'
import { Route as dashboardCampaignsCampaignIdCreativesCreativeIdIndexImport } from './routes/dashboard/campaigns/$campaignId/creatives/$creativeId/index'
import { Route as dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexImport } from './routes/dashboard/campaigns/$campaignId/creatives/$creativeId/brief/index'
import { Route as dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexImport } from './routes/dashboard/campaigns/$campaignId/creatives/$creativeId/attributes/index'
import { Route as dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexImport } from './routes/dashboard/campaigns/$campaignId/creatives/$creativeId/assets/index'

// Create Virtual Routes

const DashboardSettingsIntegrationImport = createFileRoute(
  '/_dashboard/settings/integration',
)()

// Create/Update Routes

const signLayoutRoute = signLayoutImport.update({
  id: '/sign',
  path: '/sign',
  getParentRoute: () => rootRoute,
} as any)

const dashboardLayoutRoute = dashboardLayoutImport.update({
  id: '/_dashboard',
  getParentRoute: () => rootRoute,
} as any)

const dashboardMeIndexRoute = dashboardMeIndexImport.update({
  id: '/me',
  path: '/me',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const dashboardCampaignsIndexRoute = dashboardCampaignsIndexImport.update({
  id: '/campaigns',
  path: '/campaigns',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const signIndexRoute = signIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => signLayoutRoute,
} as any)

const dashboardIndexRoute = dashboardIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const signUpRoute = signUpImport.update({
  id: '/up',
  path: '/up',
  getParentRoute: () => signLayoutRoute,
} as any)

const signOutRoute = signOutImport.update({
  id: '/out',
  path: '/out',
  getParentRoute: () => signLayoutRoute,
} as any)

const signHandleRoute = signHandleImport.update({
  id: '/handle',
  path: '/handle',
  getParentRoute: () => signLayoutRoute,
} as any)

const signConfirmRoute = signConfirmImport.update({
  id: '/confirm',
  path: '/confirm',
  getParentRoute: () => signLayoutRoute,
} as any)

const dashboardSettingsLayoutRoute = dashboardSettingsLayoutImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const dashboardPlacementsLayoutRoute = dashboardPlacementsLayoutImport.update({
  id: '/placements',
  path: '/placements',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const dashboardAttributesLayoutRoute = dashboardAttributesLayoutImport.update({
  id: '/attributes',
  path: '/attributes',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const dashboardCampaignsCampaignIdIndexRoute =
  dashboardCampaignsCampaignIdIndexImport.update({
    id: '/$campaignId',
    path: '/$campaignId',
    getParentRoute: () => dashboardCampaignsIndexRoute,
  } as any)

const dashboardPlacementsIndexRoute = dashboardPlacementsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => dashboardPlacementsLayoutRoute,
} as any)

const dashboardAttributesIndexRoute = dashboardAttributesIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => dashboardAttributesLayoutRoute,
} as any)

const signInvitationsinvitationIdRoute =
  signInvitationsinvitationIdImport.update({
    id: '/invitations/$invitationId',
    path: '/invitations/$invitationId',
    getParentRoute: () => signLayoutRoute,
  } as any)

const dashboardSettingsPreferenceRoute =
  dashboardSettingsPreferenceImport.update({
    id: '/preference',
    path: '/preference',
    getParentRoute: () => dashboardSettingsLayoutRoute,
  } as any)

const dashboardSettingsPeopleRoute = dashboardSettingsPeopleImport.update({
  id: '/people',
  path: '/people',
  getParentRoute: () => dashboardSettingsLayoutRoute,
} as any)

const DashboardSettingsIntegrationRoute =
  DashboardSettingsIntegrationImport.update({
    id: '/integration',
    path: '/integration',
    getParentRoute: () => dashboardSettingsLayoutRoute,
  } as any)

const dashboardSettingsGeneralRoute = dashboardSettingsGeneralImport.update({
  id: '/general',
  path: '/general',
  getParentRoute: () => dashboardSettingsLayoutRoute,
} as any)

const dashboardPlacementsGroupsRoute = dashboardPlacementsGroupsImport.update({
  id: '/groups',
  path: '/groups',
  getParentRoute: () => dashboardPlacementsLayoutRoute,
} as any)

const dashboardCampaignsCampaignIdCreativesIndexRoute =
  dashboardCampaignsCampaignIdCreativesIndexImport.update({
    id: '/creatives',
    path: '/creatives',
    getParentRoute: () => dashboardCampaignsCampaignIdIndexRoute,
  } as any)

const dashboardSettingsIntegrationIndexRoute =
  dashboardSettingsIntegrationIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => DashboardSettingsIntegrationRoute,
  } as any)

const dashboardSettingsIntegrationHandleRoute =
  dashboardSettingsIntegrationHandleImport.update({
    id: '/handle',
    path: '/handle',
    getParentRoute: () => DashboardSettingsIntegrationRoute,
  } as any)

const dashboardCampaignsCampaignIdCreativesCreativeIdIndexRoute =
  dashboardCampaignsCampaignIdCreativesCreativeIdIndexImport.update({
    id: '/$creativeId',
    path: '/$creativeId',
    getParentRoute: () => dashboardCampaignsCampaignIdCreativesIndexRoute,
  } as any)

const dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexRoute =
  dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexImport.update({
    id: '/brief',
    path: '/brief',
    getParentRoute: () =>
      dashboardCampaignsCampaignIdCreativesCreativeIdIndexRoute,
  } as any)

const dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexRoute =
  dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexImport.update({
    id: '/attributes',
    path: '/attributes',
    getParentRoute: () =>
      dashboardCampaignsCampaignIdCreativesCreativeIdIndexRoute,
  } as any)

const dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexRoute =
  dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexImport.update({
    id: '/assets',
    path: '/assets',
    getParentRoute: () =>
      dashboardCampaignsCampaignIdCreativesCreativeIdIndexRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_dashboard': {
      id: '/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof dashboardLayoutImport
      parentRoute: typeof rootRoute
    }
    '/sign': {
      id: '/sign'
      path: '/sign'
      fullPath: '/sign'
      preLoaderRoute: typeof signLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard/attributes': {
      id: '/_dashboard/attributes'
      path: '/attributes'
      fullPath: '/attributes'
      preLoaderRoute: typeof dashboardAttributesLayoutImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/_dashboard/placements': {
      id: '/_dashboard/placements'
      path: '/placements'
      fullPath: '/placements'
      preLoaderRoute: typeof dashboardPlacementsLayoutImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/_dashboard/settings': {
      id: '/_dashboard/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof dashboardSettingsLayoutImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/sign/confirm': {
      id: '/sign/confirm'
      path: '/confirm'
      fullPath: '/sign/confirm'
      preLoaderRoute: typeof signConfirmImport
      parentRoute: typeof signLayoutImport
    }
    '/sign/handle': {
      id: '/sign/handle'
      path: '/handle'
      fullPath: '/sign/handle'
      preLoaderRoute: typeof signHandleImport
      parentRoute: typeof signLayoutImport
    }
    '/sign/out': {
      id: '/sign/out'
      path: '/out'
      fullPath: '/sign/out'
      preLoaderRoute: typeof signOutImport
      parentRoute: typeof signLayoutImport
    }
    '/sign/up': {
      id: '/sign/up'
      path: '/up'
      fullPath: '/sign/up'
      preLoaderRoute: typeof signUpImport
      parentRoute: typeof signLayoutImport
    }
    '/_dashboard/': {
      id: '/_dashboard/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof dashboardIndexImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/sign/': {
      id: '/sign/'
      path: '/'
      fullPath: '/sign/'
      preLoaderRoute: typeof signIndexImport
      parentRoute: typeof signLayoutImport
    }
    '/_dashboard/campaigns': {
      id: '/_dashboard/campaigns'
      path: '/campaigns'
      fullPath: '/campaigns'
      preLoaderRoute: typeof dashboardCampaignsIndexImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/_dashboard/me': {
      id: '/_dashboard/me'
      path: '/me'
      fullPath: '/me'
      preLoaderRoute: typeof dashboardMeIndexImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/_dashboard/placements/groups': {
      id: '/_dashboard/placements/groups'
      path: '/groups'
      fullPath: '/placements/groups'
      preLoaderRoute: typeof dashboardPlacementsGroupsImport
      parentRoute: typeof dashboardPlacementsLayoutImport
    }
    '/_dashboard/settings/general': {
      id: '/_dashboard/settings/general'
      path: '/general'
      fullPath: '/settings/general'
      preLoaderRoute: typeof dashboardSettingsGeneralImport
      parentRoute: typeof dashboardSettingsLayoutImport
    }
    '/_dashboard/settings/integration': {
      id: '/_dashboard/settings/integration'
      path: '/integration'
      fullPath: '/settings/integration'
      preLoaderRoute: typeof DashboardSettingsIntegrationImport
      parentRoute: typeof dashboardSettingsLayoutImport
    }
    '/_dashboard/settings/people': {
      id: '/_dashboard/settings/people'
      path: '/people'
      fullPath: '/settings/people'
      preLoaderRoute: typeof dashboardSettingsPeopleImport
      parentRoute: typeof dashboardSettingsLayoutImport
    }
    '/_dashboard/settings/preference': {
      id: '/_dashboard/settings/preference'
      path: '/preference'
      fullPath: '/settings/preference'
      preLoaderRoute: typeof dashboardSettingsPreferenceImport
      parentRoute: typeof dashboardSettingsLayoutImport
    }
    '/sign/invitations/$invitationId': {
      id: '/sign/invitations/$invitationId'
      path: '/invitations/$invitationId'
      fullPath: '/sign/invitations/$invitationId'
      preLoaderRoute: typeof signInvitationsinvitationIdImport
      parentRoute: typeof signLayoutImport
    }
    '/_dashboard/attributes/': {
      id: '/_dashboard/attributes/'
      path: '/'
      fullPath: '/attributes/'
      preLoaderRoute: typeof dashboardAttributesIndexImport
      parentRoute: typeof dashboardAttributesLayoutImport
    }
    '/_dashboard/placements/': {
      id: '/_dashboard/placements/'
      path: '/'
      fullPath: '/placements/'
      preLoaderRoute: typeof dashboardPlacementsIndexImport
      parentRoute: typeof dashboardPlacementsLayoutImport
    }
    '/_dashboard/campaigns/$campaignId': {
      id: '/_dashboard/campaigns/$campaignId'
      path: '/$campaignId'
      fullPath: '/campaigns/$campaignId'
      preLoaderRoute: typeof dashboardCampaignsCampaignIdIndexImport
      parentRoute: typeof dashboardCampaignsIndexImport
    }
    '/_dashboard/settings/integration/handle': {
      id: '/_dashboard/settings/integration/handle'
      path: '/handle'
      fullPath: '/settings/integration/handle'
      preLoaderRoute: typeof dashboardSettingsIntegrationHandleImport
      parentRoute: typeof DashboardSettingsIntegrationImport
    }
    '/_dashboard/settings/integration/': {
      id: '/_dashboard/settings/integration/'
      path: '/'
      fullPath: '/settings/integration/'
      preLoaderRoute: typeof dashboardSettingsIntegrationIndexImport
      parentRoute: typeof DashboardSettingsIntegrationImport
    }
    '/_dashboard/campaigns/$campaignId/creatives': {
      id: '/_dashboard/campaigns/$campaignId/creatives'
      path: '/creatives'
      fullPath: '/campaigns/$campaignId/creatives'
      preLoaderRoute: typeof dashboardCampaignsCampaignIdCreativesIndexImport
      parentRoute: typeof dashboardCampaignsCampaignIdIndexImport
    }
    '/_dashboard/campaigns/$campaignId/creatives/$creativeId': {
      id: '/_dashboard/campaigns/$campaignId/creatives/$creativeId'
      path: '/$creativeId'
      fullPath: '/campaigns/$campaignId/creatives/$creativeId'
      preLoaderRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdIndexImport
      parentRoute: typeof dashboardCampaignsCampaignIdCreativesIndexImport
    }
    '/_dashboard/campaigns/$campaignId/creatives/$creativeId/assets': {
      id: '/_dashboard/campaigns/$campaignId/creatives/$creativeId/assets'
      path: '/assets'
      fullPath: '/campaigns/$campaignId/creatives/$creativeId/assets'
      preLoaderRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexImport
      parentRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdIndexImport
    }
    '/_dashboard/campaigns/$campaignId/creatives/$creativeId/attributes': {
      id: '/_dashboard/campaigns/$campaignId/creatives/$creativeId/attributes'
      path: '/attributes'
      fullPath: '/campaigns/$campaignId/creatives/$creativeId/attributes'
      preLoaderRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexImport
      parentRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdIndexImport
    }
    '/_dashboard/campaigns/$campaignId/creatives/$creativeId/brief': {
      id: '/_dashboard/campaigns/$campaignId/creatives/$creativeId/brief'
      path: '/brief'
      fullPath: '/campaigns/$campaignId/creatives/$creativeId/brief'
      preLoaderRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexImport
      parentRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdIndexImport
    }
  }
}

// Create and export the route tree

interface dashboardAttributesLayoutRouteChildren {
  dashboardAttributesIndexRoute: typeof dashboardAttributesIndexRoute
}

const dashboardAttributesLayoutRouteChildren: dashboardAttributesLayoutRouteChildren =
  {
    dashboardAttributesIndexRoute: dashboardAttributesIndexRoute,
  }

const dashboardAttributesLayoutRouteWithChildren =
  dashboardAttributesLayoutRoute._addFileChildren(
    dashboardAttributesLayoutRouteChildren,
  )

interface dashboardPlacementsLayoutRouteChildren {
  dashboardPlacementsGroupsRoute: typeof dashboardPlacementsGroupsRoute
  dashboardPlacementsIndexRoute: typeof dashboardPlacementsIndexRoute
}

const dashboardPlacementsLayoutRouteChildren: dashboardPlacementsLayoutRouteChildren =
  {
    dashboardPlacementsGroupsRoute: dashboardPlacementsGroupsRoute,
    dashboardPlacementsIndexRoute: dashboardPlacementsIndexRoute,
  }

const dashboardPlacementsLayoutRouteWithChildren =
  dashboardPlacementsLayoutRoute._addFileChildren(
    dashboardPlacementsLayoutRouteChildren,
  )

interface DashboardSettingsIntegrationRouteChildren {
  dashboardSettingsIntegrationHandleRoute: typeof dashboardSettingsIntegrationHandleRoute
  dashboardSettingsIntegrationIndexRoute: typeof dashboardSettingsIntegrationIndexRoute
}

const DashboardSettingsIntegrationRouteChildren: DashboardSettingsIntegrationRouteChildren =
  {
    dashboardSettingsIntegrationHandleRoute:
      dashboardSettingsIntegrationHandleRoute,
    dashboardSettingsIntegrationIndexRoute:
      dashboardSettingsIntegrationIndexRoute,
  }

const DashboardSettingsIntegrationRouteWithChildren =
  DashboardSettingsIntegrationRoute._addFileChildren(
    DashboardSettingsIntegrationRouteChildren,
  )

interface dashboardSettingsLayoutRouteChildren {
  dashboardSettingsGeneralRoute: typeof dashboardSettingsGeneralRoute
  DashboardSettingsIntegrationRoute: typeof DashboardSettingsIntegrationRouteWithChildren
  dashboardSettingsPeopleRoute: typeof dashboardSettingsPeopleRoute
  dashboardSettingsPreferenceRoute: typeof dashboardSettingsPreferenceRoute
}

const dashboardSettingsLayoutRouteChildren: dashboardSettingsLayoutRouteChildren =
  {
    dashboardSettingsGeneralRoute: dashboardSettingsGeneralRoute,
    DashboardSettingsIntegrationRoute:
      DashboardSettingsIntegrationRouteWithChildren,
    dashboardSettingsPeopleRoute: dashboardSettingsPeopleRoute,
    dashboardSettingsPreferenceRoute: dashboardSettingsPreferenceRoute,
  }

const dashboardSettingsLayoutRouteWithChildren =
  dashboardSettingsLayoutRoute._addFileChildren(
    dashboardSettingsLayoutRouteChildren,
  )

interface dashboardCampaignsCampaignIdCreativesCreativeIdIndexRouteChildren {
  dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexRoute
  dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexRoute
  dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexRoute
}

const dashboardCampaignsCampaignIdCreativesCreativeIdIndexRouteChildren: dashboardCampaignsCampaignIdCreativesCreativeIdIndexRouteChildren =
  {
    dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexRoute:
      dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexRoute,
    dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexRoute:
      dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexRoute,
    dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexRoute:
      dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexRoute,
  }

const dashboardCampaignsCampaignIdCreativesCreativeIdIndexRouteWithChildren =
  dashboardCampaignsCampaignIdCreativesCreativeIdIndexRoute._addFileChildren(
    dashboardCampaignsCampaignIdCreativesCreativeIdIndexRouteChildren,
  )

interface dashboardCampaignsCampaignIdCreativesIndexRouteChildren {
  dashboardCampaignsCampaignIdCreativesCreativeIdIndexRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdIndexRouteWithChildren
}

const dashboardCampaignsCampaignIdCreativesIndexRouteChildren: dashboardCampaignsCampaignIdCreativesIndexRouteChildren =
  {
    dashboardCampaignsCampaignIdCreativesCreativeIdIndexRoute:
      dashboardCampaignsCampaignIdCreativesCreativeIdIndexRouteWithChildren,
  }

const dashboardCampaignsCampaignIdCreativesIndexRouteWithChildren =
  dashboardCampaignsCampaignIdCreativesIndexRoute._addFileChildren(
    dashboardCampaignsCampaignIdCreativesIndexRouteChildren,
  )

interface dashboardCampaignsCampaignIdIndexRouteChildren {
  dashboardCampaignsCampaignIdCreativesIndexRoute: typeof dashboardCampaignsCampaignIdCreativesIndexRouteWithChildren
}

const dashboardCampaignsCampaignIdIndexRouteChildren: dashboardCampaignsCampaignIdIndexRouteChildren =
  {
    dashboardCampaignsCampaignIdCreativesIndexRoute:
      dashboardCampaignsCampaignIdCreativesIndexRouteWithChildren,
  }

const dashboardCampaignsCampaignIdIndexRouteWithChildren =
  dashboardCampaignsCampaignIdIndexRoute._addFileChildren(
    dashboardCampaignsCampaignIdIndexRouteChildren,
  )

interface dashboardCampaignsIndexRouteChildren {
  dashboardCampaignsCampaignIdIndexRoute: typeof dashboardCampaignsCampaignIdIndexRouteWithChildren
}

const dashboardCampaignsIndexRouteChildren: dashboardCampaignsIndexRouteChildren =
  {
    dashboardCampaignsCampaignIdIndexRoute:
      dashboardCampaignsCampaignIdIndexRouteWithChildren,
  }

const dashboardCampaignsIndexRouteWithChildren =
  dashboardCampaignsIndexRoute._addFileChildren(
    dashboardCampaignsIndexRouteChildren,
  )

interface dashboardLayoutRouteChildren {
  dashboardAttributesLayoutRoute: typeof dashboardAttributesLayoutRouteWithChildren
  dashboardPlacementsLayoutRoute: typeof dashboardPlacementsLayoutRouteWithChildren
  dashboardSettingsLayoutRoute: typeof dashboardSettingsLayoutRouteWithChildren
  dashboardIndexRoute: typeof dashboardIndexRoute
  dashboardCampaignsIndexRoute: typeof dashboardCampaignsIndexRouteWithChildren
  dashboardMeIndexRoute: typeof dashboardMeIndexRoute
}

const dashboardLayoutRouteChildren: dashboardLayoutRouteChildren = {
  dashboardAttributesLayoutRoute: dashboardAttributesLayoutRouteWithChildren,
  dashboardPlacementsLayoutRoute: dashboardPlacementsLayoutRouteWithChildren,
  dashboardSettingsLayoutRoute: dashboardSettingsLayoutRouteWithChildren,
  dashboardIndexRoute: dashboardIndexRoute,
  dashboardCampaignsIndexRoute: dashboardCampaignsIndexRouteWithChildren,
  dashboardMeIndexRoute: dashboardMeIndexRoute,
}

const dashboardLayoutRouteWithChildren = dashboardLayoutRoute._addFileChildren(
  dashboardLayoutRouteChildren,
)

interface signLayoutRouteChildren {
  signConfirmRoute: typeof signConfirmRoute
  signHandleRoute: typeof signHandleRoute
  signOutRoute: typeof signOutRoute
  signUpRoute: typeof signUpRoute
  signIndexRoute: typeof signIndexRoute
  signInvitationsinvitationIdRoute: typeof signInvitationsinvitationIdRoute
}

const signLayoutRouteChildren: signLayoutRouteChildren = {
  signConfirmRoute: signConfirmRoute,
  signHandleRoute: signHandleRoute,
  signOutRoute: signOutRoute,
  signUpRoute: signUpRoute,
  signIndexRoute: signIndexRoute,
  signInvitationsinvitationIdRoute: signInvitationsinvitationIdRoute,
}

const signLayoutRouteWithChildren = signLayoutRoute._addFileChildren(
  signLayoutRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof dashboardLayoutRouteWithChildren
  '/sign': typeof signLayoutRouteWithChildren
  '/attributes': typeof dashboardAttributesLayoutRouteWithChildren
  '/placements': typeof dashboardPlacementsLayoutRouteWithChildren
  '/settings': typeof dashboardSettingsLayoutRouteWithChildren
  '/sign/confirm': typeof signConfirmRoute
  '/sign/handle': typeof signHandleRoute
  '/sign/out': typeof signOutRoute
  '/sign/up': typeof signUpRoute
  '/': typeof dashboardIndexRoute
  '/sign/': typeof signIndexRoute
  '/campaigns': typeof dashboardCampaignsIndexRouteWithChildren
  '/me': typeof dashboardMeIndexRoute
  '/placements/groups': typeof dashboardPlacementsGroupsRoute
  '/settings/general': typeof dashboardSettingsGeneralRoute
  '/settings/integration': typeof DashboardSettingsIntegrationRouteWithChildren
  '/settings/people': typeof dashboardSettingsPeopleRoute
  '/settings/preference': typeof dashboardSettingsPreferenceRoute
  '/sign/invitations/$invitationId': typeof signInvitationsinvitationIdRoute
  '/attributes/': typeof dashboardAttributesIndexRoute
  '/placements/': typeof dashboardPlacementsIndexRoute
  '/campaigns/$campaignId': typeof dashboardCampaignsCampaignIdIndexRouteWithChildren
  '/settings/integration/handle': typeof dashboardSettingsIntegrationHandleRoute
  '/settings/integration/': typeof dashboardSettingsIntegrationIndexRoute
  '/campaigns/$campaignId/creatives': typeof dashboardCampaignsCampaignIdCreativesIndexRouteWithChildren
  '/campaigns/$campaignId/creatives/$creativeId': typeof dashboardCampaignsCampaignIdCreativesCreativeIdIndexRouteWithChildren
  '/campaigns/$campaignId/creatives/$creativeId/assets': typeof dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexRoute
  '/campaigns/$campaignId/creatives/$creativeId/attributes': typeof dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexRoute
  '/campaigns/$campaignId/creatives/$creativeId/brief': typeof dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexRoute
}

export interface FileRoutesByTo {
  '/settings': typeof dashboardSettingsLayoutRouteWithChildren
  '/sign/confirm': typeof signConfirmRoute
  '/sign/handle': typeof signHandleRoute
  '/sign/out': typeof signOutRoute
  '/sign/up': typeof signUpRoute
  '/': typeof dashboardIndexRoute
  '/sign': typeof signIndexRoute
  '/campaigns': typeof dashboardCampaignsIndexRouteWithChildren
  '/me': typeof dashboardMeIndexRoute
  '/placements/groups': typeof dashboardPlacementsGroupsRoute
  '/settings/general': typeof dashboardSettingsGeneralRoute
  '/settings/people': typeof dashboardSettingsPeopleRoute
  '/settings/preference': typeof dashboardSettingsPreferenceRoute
  '/sign/invitations/$invitationId': typeof signInvitationsinvitationIdRoute
  '/attributes': typeof dashboardAttributesIndexRoute
  '/placements': typeof dashboardPlacementsIndexRoute
  '/campaigns/$campaignId': typeof dashboardCampaignsCampaignIdIndexRouteWithChildren
  '/settings/integration/handle': typeof dashboardSettingsIntegrationHandleRoute
  '/settings/integration': typeof dashboardSettingsIntegrationIndexRoute
  '/campaigns/$campaignId/creatives': typeof dashboardCampaignsCampaignIdCreativesIndexRouteWithChildren
  '/campaigns/$campaignId/creatives/$creativeId': typeof dashboardCampaignsCampaignIdCreativesCreativeIdIndexRouteWithChildren
  '/campaigns/$campaignId/creatives/$creativeId/assets': typeof dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexRoute
  '/campaigns/$campaignId/creatives/$creativeId/attributes': typeof dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexRoute
  '/campaigns/$campaignId/creatives/$creativeId/brief': typeof dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_dashboard': typeof dashboardLayoutRouteWithChildren
  '/sign': typeof signLayoutRouteWithChildren
  '/_dashboard/attributes': typeof dashboardAttributesLayoutRouteWithChildren
  '/_dashboard/placements': typeof dashboardPlacementsLayoutRouteWithChildren
  '/_dashboard/settings': typeof dashboardSettingsLayoutRouteWithChildren
  '/sign/confirm': typeof signConfirmRoute
  '/sign/handle': typeof signHandleRoute
  '/sign/out': typeof signOutRoute
  '/sign/up': typeof signUpRoute
  '/_dashboard/': typeof dashboardIndexRoute
  '/sign/': typeof signIndexRoute
  '/_dashboard/campaigns': typeof dashboardCampaignsIndexRouteWithChildren
  '/_dashboard/me': typeof dashboardMeIndexRoute
  '/_dashboard/placements/groups': typeof dashboardPlacementsGroupsRoute
  '/_dashboard/settings/general': typeof dashboardSettingsGeneralRoute
  '/_dashboard/settings/integration': typeof DashboardSettingsIntegrationRouteWithChildren
  '/_dashboard/settings/people': typeof dashboardSettingsPeopleRoute
  '/_dashboard/settings/preference': typeof dashboardSettingsPreferenceRoute
  '/sign/invitations/$invitationId': typeof signInvitationsinvitationIdRoute
  '/_dashboard/attributes/': typeof dashboardAttributesIndexRoute
  '/_dashboard/placements/': typeof dashboardPlacementsIndexRoute
  '/_dashboard/campaigns/$campaignId': typeof dashboardCampaignsCampaignIdIndexRouteWithChildren
  '/_dashboard/settings/integration/handle': typeof dashboardSettingsIntegrationHandleRoute
  '/_dashboard/settings/integration/': typeof dashboardSettingsIntegrationIndexRoute
  '/_dashboard/campaigns/$campaignId/creatives': typeof dashboardCampaignsCampaignIdCreativesIndexRouteWithChildren
  '/_dashboard/campaigns/$campaignId/creatives/$creativeId': typeof dashboardCampaignsCampaignIdCreativesCreativeIdIndexRouteWithChildren
  '/_dashboard/campaigns/$campaignId/creatives/$creativeId/assets': typeof dashboardCampaignsCampaignIdCreativesCreativeIdAssetsIndexRoute
  '/_dashboard/campaigns/$campaignId/creatives/$creativeId/attributes': typeof dashboardCampaignsCampaignIdCreativesCreativeIdAttributesIndexRoute
  '/_dashboard/campaigns/$campaignId/creatives/$creativeId/brief': typeof dashboardCampaignsCampaignIdCreativesCreativeIdBriefIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/sign'
    | '/attributes'
    | '/placements'
    | '/settings'
    | '/sign/confirm'
    | '/sign/handle'
    | '/sign/out'
    | '/sign/up'
    | '/'
    | '/sign/'
    | '/campaigns'
    | '/me'
    | '/placements/groups'
    | '/settings/general'
    | '/settings/integration'
    | '/settings/people'
    | '/settings/preference'
    | '/sign/invitations/$invitationId'
    | '/attributes/'
    | '/placements/'
    | '/campaigns/$campaignId'
    | '/settings/integration/handle'
    | '/settings/integration/'
    | '/campaigns/$campaignId/creatives'
    | '/campaigns/$campaignId/creatives/$creativeId'
    | '/campaigns/$campaignId/creatives/$creativeId/assets'
    | '/campaigns/$campaignId/creatives/$creativeId/attributes'
    | '/campaigns/$campaignId/creatives/$creativeId/brief'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/settings'
    | '/sign/confirm'
    | '/sign/handle'
    | '/sign/out'
    | '/sign/up'
    | '/'
    | '/sign'
    | '/campaigns'
    | '/me'
    | '/placements/groups'
    | '/settings/general'
    | '/settings/people'
    | '/settings/preference'
    | '/sign/invitations/$invitationId'
    | '/attributes'
    | '/placements'
    | '/campaigns/$campaignId'
    | '/settings/integration/handle'
    | '/settings/integration'
    | '/campaigns/$campaignId/creatives'
    | '/campaigns/$campaignId/creatives/$creativeId'
    | '/campaigns/$campaignId/creatives/$creativeId/assets'
    | '/campaigns/$campaignId/creatives/$creativeId/attributes'
    | '/campaigns/$campaignId/creatives/$creativeId/brief'
  id:
    | '__root__'
    | '/_dashboard'
    | '/sign'
    | '/_dashboard/attributes'
    | '/_dashboard/placements'
    | '/_dashboard/settings'
    | '/sign/confirm'
    | '/sign/handle'
    | '/sign/out'
    | '/sign/up'
    | '/_dashboard/'
    | '/sign/'
    | '/_dashboard/campaigns'
    | '/_dashboard/me'
    | '/_dashboard/placements/groups'
    | '/_dashboard/settings/general'
    | '/_dashboard/settings/integration'
    | '/_dashboard/settings/people'
    | '/_dashboard/settings/preference'
    | '/sign/invitations/$invitationId'
    | '/_dashboard/attributes/'
    | '/_dashboard/placements/'
    | '/_dashboard/campaigns/$campaignId'
    | '/_dashboard/settings/integration/handle'
    | '/_dashboard/settings/integration/'
    | '/_dashboard/campaigns/$campaignId/creatives'
    | '/_dashboard/campaigns/$campaignId/creatives/$creativeId'
    | '/_dashboard/campaigns/$campaignId/creatives/$creativeId/assets'
    | '/_dashboard/campaigns/$campaignId/creatives/$creativeId/attributes'
    | '/_dashboard/campaigns/$campaignId/creatives/$creativeId/brief'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  dashboardLayoutRoute: typeof dashboardLayoutRouteWithChildren
  signLayoutRoute: typeof signLayoutRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  dashboardLayoutRoute: dashboardLayoutRouteWithChildren,
  signLayoutRoute: signLayoutRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_dashboard",
        "/sign"
      ]
    },
    "/_dashboard": {
      "filePath": "dashboard/_layout.tsx",
      "children": [
        "/_dashboard/attributes",
        "/_dashboard/placements",
        "/_dashboard/settings",
        "/_dashboard/",
        "/_dashboard/campaigns",
        "/_dashboard/me"
      ]
    },
    "/sign": {
      "filePath": "sign/_layout.tsx",
      "children": [
        "/sign/confirm",
        "/sign/handle",
        "/sign/out",
        "/sign/up",
        "/sign/",
        "/sign/invitations/$invitationId"
      ]
    },
    "/_dashboard/attributes": {
      "filePath": "dashboard/attributes/_layout.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/attributes/"
      ]
    },
    "/_dashboard/placements": {
      "filePath": "dashboard/placements/_layout.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/placements/groups",
        "/_dashboard/placements/"
      ]
    },
    "/_dashboard/settings": {
      "filePath": "dashboard/settings/_layout.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/settings/general",
        "/_dashboard/settings/integration",
        "/_dashboard/settings/people",
        "/_dashboard/settings/preference"
      ]
    },
    "/sign/confirm": {
      "filePath": "sign/confirm.tsx",
      "parent": "/sign"
    },
    "/sign/handle": {
      "filePath": "sign/handle.tsx",
      "parent": "/sign"
    },
    "/sign/out": {
      "filePath": "sign/out.tsx",
      "parent": "/sign"
    },
    "/sign/up": {
      "filePath": "sign/up.tsx",
      "parent": "/sign"
    },
    "/_dashboard/": {
      "filePath": "dashboard/index.tsx",
      "parent": "/_dashboard"
    },
    "/sign/": {
      "filePath": "sign/index.tsx",
      "parent": "/sign"
    },
    "/_dashboard/campaigns": {
      "filePath": "dashboard/campaigns/index.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/campaigns/$campaignId"
      ]
    },
    "/_dashboard/me": {
      "filePath": "dashboard/me/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/placements/groups": {
      "filePath": "dashboard/placements/groups.tsx",
      "parent": "/_dashboard/placements"
    },
    "/_dashboard/settings/general": {
      "filePath": "dashboard/settings/general.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/settings/integration": {
      "filePath": "",
      "parent": "/_dashboard/settings",
      "children": [
        "/_dashboard/settings/integration/handle",
        "/_dashboard/settings/integration/"
      ]
    },
    "/_dashboard/settings/people": {
      "filePath": "dashboard/settings/people.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/settings/preference": {
      "filePath": "dashboard/settings/preference.tsx",
      "parent": "/_dashboard/settings"
    },
    "/sign/invitations/$invitationId": {
      "filePath": "sign/invitations.$invitationId.tsx",
      "parent": "/sign"
    },
    "/_dashboard/attributes/": {
      "filePath": "dashboard/attributes/index.tsx",
      "parent": "/_dashboard/attributes"
    },
    "/_dashboard/placements/": {
      "filePath": "dashboard/placements/index.tsx",
      "parent": "/_dashboard/placements"
    },
    "/_dashboard/campaigns/$campaignId": {
      "filePath": "dashboard/campaigns/$campaignId/index.tsx",
      "parent": "/_dashboard/campaigns",
      "children": [
        "/_dashboard/campaigns/$campaignId/creatives"
      ]
    },
    "/_dashboard/settings/integration/handle": {
      "filePath": "dashboard/settings/integration/handle.tsx",
      "parent": "/_dashboard/settings/integration"
    },
    "/_dashboard/settings/integration/": {
      "filePath": "dashboard/settings/integration/index.tsx",
      "parent": "/_dashboard/settings/integration"
    },
    "/_dashboard/campaigns/$campaignId/creatives": {
      "filePath": "dashboard/campaigns/$campaignId/creatives/index.tsx",
      "parent": "/_dashboard/campaigns/$campaignId",
      "children": [
        "/_dashboard/campaigns/$campaignId/creatives/$creativeId"
      ]
    },
    "/_dashboard/campaigns/$campaignId/creatives/$creativeId": {
      "filePath": "dashboard/campaigns/$campaignId/creatives/$creativeId/index.tsx",
      "parent": "/_dashboard/campaigns/$campaignId/creatives",
      "children": [
        "/_dashboard/campaigns/$campaignId/creatives/$creativeId/assets",
        "/_dashboard/campaigns/$campaignId/creatives/$creativeId/attributes",
        "/_dashboard/campaigns/$campaignId/creatives/$creativeId/brief"
      ]
    },
    "/_dashboard/campaigns/$campaignId/creatives/$creativeId/assets": {
      "filePath": "dashboard/campaigns/$campaignId/creatives/$creativeId/assets/index.tsx",
      "parent": "/_dashboard/campaigns/$campaignId/creatives/$creativeId"
    },
    "/_dashboard/campaigns/$campaignId/creatives/$creativeId/attributes": {
      "filePath": "dashboard/campaigns/$campaignId/creatives/$creativeId/attributes/index.tsx",
      "parent": "/_dashboard/campaigns/$campaignId/creatives/$creativeId"
    },
    "/_dashboard/campaigns/$campaignId/creatives/$creativeId/brief": {
      "filePath": "dashboard/campaigns/$campaignId/creatives/$creativeId/brief/index.tsx",
      "parent": "/_dashboard/campaigns/$campaignId/creatives/$creativeId"
    }
  }
}
ROUTE_MANIFEST_END */
