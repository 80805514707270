import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { debounce } from 'radash';
import Brief from '#app/components/Brief.tsx';
import useAccess from '#app/hooks/use-access.ts';
import useCreative from '#app/hooks/use-creative.ts';
import { getCreativeQueryOptions } from '#app/queries/creative.js';

export const Route = createFileRoute(
	'/_dashboard/campaigns/$campaignId/creatives/$creativeId/brief',
)({
	component: RouteComponent,
	staleTime: 0,
	loader: async ({ params, context }) => {
		const creative = await context.queryClient.fetchQuery(
			getCreativeQueryOptions(params.creativeId),
		);

		return creative;
	},
});

function RouteComponent() {
	const { creativeId } = Route.useParams();
	const { data: creative } = useSuspenseQuery(getCreativeQueryOptions(creativeId));
	const { hasPermission } = useAccess();
	const { updateCreative } = useCreative(creative.campaignId);

	const onBriefChange = debounce({ delay: 1000 }, (value: string) => {
		updateCreative.mutate({
			param: {
				creativeId: creative.id,
			},
			json: {
				description: value,
			},
		});
	});

	return (
		<div className='flex justify-center pt-6'>
			<div className='min-w-4/5'>
				<Brief
					value={creative.description!}
					onChange={onBriefChange}
					canEdit={hasPermission('creative-update')}
				/>
			</div>
		</div>
	);
}
