import { createFileRoute } from '@tanstack/react-router';
import type { RowSelectionState, Updater } from '@tanstack/react-table';
import { Plus } from 'lucide-react';
import { useCallback, useState } from 'react';

import type { Attribute } from '@adframe/database-adframe/types';
import { useTranslation } from '@adframe/translation';
import { Button } from '@adframe/ui/components/button';
import { DataTable } from '@adframe/ui/components/data-table';
import HasPermission from '#app/components/HasPermission.js';
import useAttribute from '#app/hooks/use-attribute.ts';
import { useSheet } from '#app/providers/sheet.js';
import { listAttributesOptions } from '#app/queries/attribute.ts';
import { attributeColumns } from './columns/attributes';
import AttributeForm from './components/AttributeForm';
import type { AttributeFormOutput } from './components/type';

export const Route = createFileRoute('/_dashboard/attributes/')({
	component: RouteComponent,
	loader: async ({ context }) => context.queryClient.ensureQueryData(listAttributesOptions()),
});

function RouteComponent() {
	const { t } = useTranslation();
	const sheet = useSheet();
	const [attributeSelected, setAttributeSelection] = useState<Attribute | undefined>();
	const { attributes, updateAttribute, createAttribute, deleteAttribute } = useAttribute();

	const onRowSelected = useCallback(
		(value: Updater<RowSelectionState>) => {
			const id = Object.entries(
				typeof value === 'function'
					? value(attributeSelected ? { [attributeSelected.id]: true } : {})
					: value,
			)[0]?.[0];

			const attribute = attributes.find((row) => row.id === id);

			if (attribute) {
				setAttributeSelection(attribute);
				sheet.showSheet({
					onClose: () => {
						setAttributeSelection(undefined);
					},
					title: 'Attribute',
					content: (
						<AttributeForm
							attribute={attribute}
							onSubmit={onSubmit}
							onRemove={async () => {
								await deleteAttribute.mutateAsync({
									param: {
										attributeId: attribute.id,
									},
								});

								sheet.hideSheet();
							}}
							onCancel={() => {
								setAttributeSelection(undefined);
								sheet.hideSheet();
							}}
						/>
					),
				});
			}
		},
		[attributes],
	);

	const onSubmit = useCallback(
		async (form: AttributeFormOutput) => {
			console.log(form);

			if (form.id) {
				await updateAttribute.mutateAsync({
					json: {
						global: form.global,
						label: form.label,
						description: form.description,
						params: form.params,
					} as AttributeFormOutput,
					param: {
						attributeId: form.id,
					},
				});
			} else {
				await createAttribute.mutateAsync({
					json: {
						global: form.global,
						label: form.label,
						description: form.description,
						params: form.params,
					} as AttributeFormOutput,
				});
			}
			setAttributeSelection(undefined);
			sheet.hideSheet();
		},
		[attributeSelected],
	);

	return (
		<>
			<div className='flex flex-col gap-3'>
				<div className='flex justify-between gap-3'>
					<div className='-ml-2 -mt-2 flex w-2/3 flex-wrap items-baseline'>
						<h1 className='mt-2 ml-2 font-semibold text-base text-foreground leading-6'>
							{t('attribute.menu')}
						</h1>
						<p className='mt-1 ml-2 truncate text-muted-foreground text-sm'>
							{t('attribute.description')}
						</p>
					</div>
					<div>
						<HasPermission permission='attribute-create'>
							<Button
								type='button'
								size={'sm'}
								onClick={() => {
									sheet.showSheet({
										content: (
											<AttributeForm
												onSubmit={onSubmit}
												onCancel={() => {
													sheet.hideSheet();
													setAttributeSelection(undefined);
												}}
											/>
										),
									});
								}}
							>
								<Plus className='mr-2 size-4' />
								{t('placement.create')}
							</Button>
						</HasPermission>
					</div>
				</div>

				<DataTable
					columns={attributeColumns()}
					data={attributes ?? []}
					enableRowSelection={true}
					enableMultiRowSelection={false}
					onRowSelectionChange={onRowSelected}
					rowSelection={attributeSelected ? { [attributeSelected.id]: true } : {}}
				/>
			</div>
		</>
	);
}
