import { createFileRoute } from '@tanstack/react-router';
import useAttributeAssignment from '#app/hooks/use-attribute-assignment.ts';
import { getCreativeQueryOptions } from '#app/queries/creative.js';
import AttributeField from './components/AttributeField.tsx';

export const Route = createFileRoute(
	'/_dashboard/campaigns/$campaignId/creatives/$creativeId/attributes',
)({
	component: RouteComponent,
	staleTime: 0,
	loader: async ({ params, context }) => {
		const creative = await context.queryClient.fetchQuery(
			getCreativeQueryOptions(params.creativeId),
		);

		return creative;
	},
});

function RouteComponent() {
	const { creativeId } = Route.useParams();
	const { attributeAssignments } = useAttributeAssignment(creativeId as `crea_${string}`);

	return (
		<div className='flex justify-center pt-6'>
			<div className='min-w-4/5 flex flex-col gap-6'>
				{attributeAssignments.map((attributeAssignment) => (
					<AttributeField
						key={attributeAssignment.attributeId}
						attributeAssignment={attributeAssignment}
					/>
				))}
			</div>
		</div>
	);
}
