import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { InferRequestType } from 'hono';

import type { PreferenceParams } from '@adframe/database-adframe/queries';

import getApiClient from '../services/internal-api/get-api-client';

export const preferenceQueryOptions = queryOptions({
	queryKey: ['preference'],
	queryFn: () =>
		getApiClient()
			.preferences.$get()
			.then((result) => result.json() as Promise<PreferenceParams>),
});

export default function useSetting() {
	const queryClient = useQueryClient();
	const { preferences: preferenceClient } = getApiClient();

	const { data: preference } = useQuery(preferenceQueryOptions);

	const mutatePreference = useMutation({
		mutationFn: async (request: InferRequestType<typeof preferenceClient.$post>) =>
			preferenceClient.$post(request).then((result) => result.json()),
		onSuccess: (result) => {
			queryClient.setQueryData(['preference'], result);
		},
	});

	return {
		preference,
		mutatePreference,
	};
}
