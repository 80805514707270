import { createFileRoute, redirect } from '@tanstack/react-router';

import { Badge } from '@adframe/ui/components/badge';
import { SidebarProvider } from '@adframe/ui/components/sidebar';
import { Outlet } from '@tanstack/react-router';
import PaymentStatus from '#app/components/PaymentStatus.js';
import { AppSidebar } from '#app/components/sidebar/index.js';
import useAccess from '#app/hooks/use-access.ts';
import usePaymentStatus from '#app/hooks/use-payment-status.js';

export const Route = createFileRoute('/_dashboard')({
	component: RouteComponent,
	beforeLoad: ({ context }) => {
		if (context.auth.status === 'anonymous') throw redirect({ to: '/sign' });
	},
});

function RouteComponent() {
	const status = usePaymentStatus();
	const { role } = useAccess();

	const shouldPaiementWarning = ['unpaid', 'canceled', 'past_due', 'paused'].includes(status);

	return (
		<SidebarProvider>
			<AppSidebar />
			<main className='w-full'>
				{shouldPaiementWarning && role !== 'super_hero' ? (
					<PaymentStatus status={status} />
				) : (
					<>
						{shouldPaiementWarning && role === 'super_hero' && (
							<div className='absolute bottom-2 right-2'>
								<Badge
									variant={
										status === 'active'
											? 'default'
											: status === 'trialing'
												? 'secondary'
												: 'destructive'
									}
								>
									{status}
								</Badge>
							</div>
						)}
						<Outlet />
					</>
				)}
			</main>
		</SidebarProvider>
	);
}
