import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, notFound, redirect } from '@tanstack/react-router';
import { Outlet } from '@tanstack/react-router';

import { getCampaignQueryOptions } from '#app/queries/index.js';

import { HTTPException } from 'hono/http-exception';
import { CampaignProvider } from './contexts';
import { Route as creativesRoute } from './creatives';

export const Route = createFileRoute('/_dashboard/campaigns/$campaignId')({
	component: RouteComponent,
	staleTime: 0,
	beforeLoad: async ({ params, location, context, preload }) => {
		const campaign = await context.queryClient
			.fetchQuery(getCampaignQueryOptions(params.campaignId as `cmp_${string}`))
			.catch((error) => {
				if (error instanceof HTTPException && error.status === 404) {
					throw notFound();
				}

				throw error;
			});

		const isOnCreativesPage = /\/campaigns\/cmp_[^/]+\/creatives.*/g.test(location.pathname);

		if (!isOnCreativesPage && !preload) {
			throw redirect({
				to: creativesRoute.to,
				from: '/campaigns/$campaignId',
				params: {
					campaignId: campaign.id,
				},
			});
		}

		return campaign;
	},
});

function RouteComponent() {
	const params = Route.useParams();

	const { data: campaign } = useSuspenseQuery(
		getCampaignQueryOptions(params.campaignId as `cmp_${string}`),
	);

	return (
		<>
			<CampaignProvider value={campaign}>
				<Outlet />
			</CampaignProvider>
		</>
	);
}
