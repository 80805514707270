import { queryOptions } from '@tanstack/react-query';
import getAccountClient from '../services/internal-api/get-account-client';

export const listInvitationsQueryOptions = queryOptions({
	queryKey: ['invitations'],
	initialData: [],
	select: (data) => data.filter(({ confirmedAt }) => !confirmedAt),
	queryFn: () =>
		getAccountClient()
			.invitations.$get()
			.then((result) => result.json()),
});
