import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { Link, useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';
import logo from '#app/assets/adframe-logo-light-mode.png';
import getSecurityClient from '#app/services/internal-api/get-security-client.js';

import { Button } from '@adframe/ui/components/button';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@adframe/ui/components/form';
import { Input } from '@adframe/ui/components/input';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import useAuth from '#app/hooks/use-auth.js';

export const Route = createFileRoute('/sign/invitations/$invitationId')({
	component: RouteComponent,
	errorComponent: ErrorComponent,
	loader: async ({ params }) => {
		const client = getSecurityClient();

		return client.invitations[':invitationId']
			.$get({
				param: {
					invitationId: params.invitationId,
				},
			})
			.then((result) => result.json());
	},
	staticData: {
		guest: true,
	},
});

const formSchema = z.object({
	firstName: z.string().min(2),
	lastName: z.string().min(2),
});

function ErrorComponent() {
	return (
		<>
			<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
				<img className='mx-auto h-10 w-auto' src={logo} alt='AdFrame Logo' />
				<h2 className='mt-10 text-center font-bold text-2xl text-back leading-9 tracking-tight'>
					Invitation expired or not found
				</h2>
				<div className='mt-12 flex justify-center'>
					<Link to='/sign'>
						<Button variant={'secondary'}>Back to sign</Button>
					</Link>
				</div>
			</div>
		</>
	);
}

function RouteComponent() {
	const { registerInvitation } = useAuth();
	const { invitationId } = Route.useParams();
	const { firstName, lastName, organizationName } = Route.useLoaderData();
	const navigate = useNavigate({ from: '/sign/invitations/$invitationId' });
	const [isLoading, setLoading] = useState(false);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			firstName: firstName ?? '',
			lastName: lastName ?? '',
		},
	});

	async function onSubmit(data: z.output<typeof formSchema>) {
		try {
			setLoading(true);
			await registerInvitation({
				...data,
				invitationId,
			});

			setTimeout(async () => {
				setLoading(false);
				await navigate({ to: '/' });
			}, 6000);
		} catch (error) {
			toast.error('Something went wrong', { position: 'top-center' });
		}
	}

	return (
		<>
			<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
				<img className='mx-auto h-10 w-auto' src={logo} alt='AdFrame Logo' />
				<h2 className='mt-10 text-center font-bold text-2xl text-back leading-9 tracking-tight'>
					Welcome to {organizationName}
				</h2>
			</div>

			<div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
						<FormField
							control={form.control}
							name='firstName'
							render={({ field }) => (
								<FormItem>
									<FormLabel>First name</FormLabel>
									<FormControl>
										<Input
											placeholder='John'
											className='dark:bg-gray-800'
											autoComplete='off'
											{...field}
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name='lastName'
							render={({ field }) => (
								<FormItem>
									<FormLabel>Last name</FormLabel>
									<FormControl>
										<Input
											placeholder='Doe'
											className='dark:bg-gray-800'
											autoComplete='off'
											{...field}
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<div className='flex gap-6'>
							<div className='w-full'>
								<Button disabled={isLoading} type='submit' className='inline-flex w-full gap-3'>
									{!!isLoading && <Loader2 className='animate-spin text-lg' />}
									Let's start
								</Button>
							</div>
						</div>
					</form>
				</Form>
			</div>
		</>
	);
}
