import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, notFound, redirect } from '@tanstack/react-router';
import { Outlet } from '@tanstack/react-router';

import { listCreativesFromCampaignQueryOptions } from '#app/queries/index.js';

import { HTTPException } from 'hono/http-exception';
import { useCampaignContext } from '../contexts';
import { Route as creativeRoute } from './$creativeId';
import { CreativeNavbarProvider } from './$creativeId/contexts';
import CreativeRoot from './components/creative/CreativeRoot';

export const Route = createFileRoute('/_dashboard/campaigns/$campaignId/creatives')({
	component: RouteComponent,
	staleTime: 0,
	beforeLoad: async ({ params, location, context, preload }) => {
		const creatives = await context.queryClient
			.fetchQuery(listCreativesFromCampaignQueryOptions(params.campaignId))
			.catch((error) => {
				if (error instanceof HTTPException && error.status === 404) {
					throw notFound();
				}

				throw error;
			});

		const isOnCreativePage = /\/campaigns\/cmp_[^/]+\/creatives\/crea_.+/g.test(location.pathname);

		const creative = creatives
			.filter(({ deletedAt }) => !deletedAt)
			.sort((a, b) => a.createdAt - b.createdAt)
			.at(0);

		if (!isOnCreativePage && creative && !preload) {
			throw redirect({
				to: creativeRoute.to,
				from: '/campaigns/$campaignId/creatives',
				params: {
					...params,
					creativeId: creative.id,
				},
			});
		}

		return creatives;
	},
});

function RouteComponent() {
	const params = Route.useParams();
	const campaign = useCampaignContext();

	const { data: creatives } = useSuspenseQuery(
		listCreativesFromCampaignQueryOptions(params.campaignId as `cmp_${string}`),
	);

	return (
		<>
			<main className='md:pl-72'>
				<div className='xl:pl-24'>
					<div className='min-h-screen'>
						<div>
							<CreativeNavbarProvider>
								<Outlet />
							</CreativeNavbarProvider>
						</div>
					</div>
				</div>
			</main>

			<aside className='fixed inset-y-0 hidden w-72 overflow-hidden border-border border-r md:block xl:w-96'>
				<CreativeRoot campaign={campaign!} creatives={creatives} />
			</aside>
		</>
	);
}
