import { z } from 'zod';

export const availableProviders = [
	{
		id: 'meta',
		enable: true,
	},
	{
		id: 'gads',
		enable: false,
	},
	{
		id: 'tiktok',
		enable: false,
	},
	{
		id: 'snapchat',
		enable: false,
	},
	{
		id: 'dv360',
		enable: false,
	},
] as const;

export type AvailableProviders = (typeof availableProviders)[number]['id'];

export const validateProvider = z.union([
	z.literal('meta'),
	z.literal('tiktok'),
	z.literal('snapchat'),
	z.literal('gads'),
	z.literal('dv360'),
]);
