import { relations } from 'drizzle-orm';
import { primaryKey, sqliteTable, text } from 'drizzle-orm/sqlite-core';

import { advertisers } from './advertisers';
import { tokens } from './tokens';

export const advertisersToTokens = sqliteTable(
	'advertisers_to_tokens',
	{
		advertiserId: text('advertiser_id')
			.references(() => advertisers.id, { onDelete: 'cascade' })
			.$type<`acc_${string}`>()
			.notNull(),

		tokenId: text('token_id')
			.references(() => tokens.id, { onDelete: 'cascade' })
			.$type<`tkn_${string}`>()
			.notNull(),
	},
	(table) => ({
		pk: primaryKey({ columns: [table.advertiserId, table.tokenId] }),
	}),
);

export const advertisersToTokensRelations = relations(advertisersToTokens, ({ one }) => ({
	advertiser: one(advertisers, {
		fields: [advertisersToTokens.advertiserId],
		references: [advertisers.id],
	}),
	token: one(tokens, {
		fields: [advertisersToTokens.tokenId],
		references: [tokens.id],
	}),
}));
