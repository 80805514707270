import { TooltipProvider } from '@adframe/ui/components/tooltip';
import { HeadContent, Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { Toaster } from 'sonner';
import type useAuth from '#app/hooks/use-auth.js';
import { AlertProvider } from '#app/providers/alert.js';
import { DialogProvider } from '#app/providers/dialog.js';
import { SheetProvider } from '#app/providers/sheet.js';
import type { queryClient } from '#app/queries/index.js';

export interface RouterContext {
	auth: ReturnType<typeof useAuth>;
	queryClient: typeof queryClient;
}

export const Route = createRootRouteWithContext<RouterContext>()({
	component: RootComponent,
});

const TanStackRouterDevtools =
	process.env.NODE_ENV === 'production'
		? () => null // Render nothing in production
		: lazy(() =>
				// Lazy load in development
				import('@tanstack/router-devtools').then((res) => ({
					default: res.TanStackRouterDevtools,
				})),
			);

function RootComponent() {
	return (
		<>
			<TooltipProvider delayDuration={300}>
				<AlertProvider>
					<DialogProvider>
						<SheetProvider>
							<HeadContent />
							<Outlet />
						</SheetProvider>
					</DialogProvider>
				</AlertProvider>
			</TooltipProvider>
			<Toaster />
			<Suspense>
				<TanStackRouterDevtools initialIsOpen={false} position='bottom-right' />
			</Suspense>
		</>
	);
}
