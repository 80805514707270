import { queryOptions } from '@tanstack/react-query';
import getAccountClient from '../services/internal-api/get-account-client';

export const listMembersQueryOptions = queryOptions({
	queryKey: ['members'],
	initialData: [],
	queryFn: () =>
		getAccountClient()
			.members.$get()
			.then((result) => result.json()),
});
