import { queryOptions } from '@tanstack/react-query';
import getApiClient from '../services/internal-api/get-api-client';

export const listAttributesOptions = () =>
	queryOptions({
		queryKey: ['attributes'],
		initialData: [],
		queryFn: async () => {
			const client = getApiClient();

			return client.attributes.$get().then((result) => result.json());
		},
	});
