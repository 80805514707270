import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { InferRequestType } from 'hono';

import type { PlacementGroupWithPlacements } from '@adframe/database-adframe/types';

import getApiClient from '../services/internal-api/get-api-client';

export const placementGroupsQueryOptions = queryOptions({
	queryKey: ['placement-groups'],
	initialData: [],
	queryFn: () => {
		const client = getApiClient();

		return client['placement-groups']
			.$get()
			.then((result) => result.json() as Promise<PlacementGroupWithPlacements[]>);
	},
});

export default function usePlacement() {
	const queryClient = useQueryClient();
	const { 'placement-groups': placementGroupClient } = getApiClient();

	const { data: placementGroups } = useQuery(placementGroupsQueryOptions);

	const createPlacementGroup = useMutation({
		mutationFn: async (request: InferRequestType<typeof placementGroupClient.$post>) =>
			placementGroupClient.$post(request).then((result) => result.json()),
		onSuccess: (result) => {
			queryClient.setQueryData(['placement-groups'], [...placementGroups, result]);
		},
	});

	const updatePlacementGroup = useMutation({
		mutationFn: async (
			request: InferRequestType<(typeof placementGroupClient)[':placementGroupId']['$put']>,
		) => placementGroupClient[':placementGroupId'].$put(request).then((result) => result.json()),
		onSuccess: (result) => {
			queryClient.setQueryData(
				['placement-groups'],
				placementGroups.map((placementGroup) =>
					placementGroup.id === result.id ? result : placementGroup,
				),
			);
		},
	});

	const deletePlacementGroup = useMutation({
		mutationFn: async (
			request: InferRequestType<(typeof placementGroupClient)[':placementGroupId']['$delete']>,
		) => placementGroupClient[':placementGroupId'].$delete(request).then((result) => result.json()),
		onSuccess: (result) => {
			queryClient.setQueryData(
				['placement-groups'],
				placementGroups.map((placementGroup) =>
					placementGroup.id === result.id ? result : placementGroup,
				),
			);
		},
	});

	return {
		placementGroups,
		createPlacementGroup,
		updatePlacementGroup,
		deletePlacementGroup,
	};
}
