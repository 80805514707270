import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@adframe/ui/utils';

const buttonVariants = cva(
	'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0  aria-disabled:cursor-not-allowed cursor-pointer',
	{
		variants: {
			variant: {
				default: 'bg-primary text-primary-foreground hover:bg-primary/90',
				destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
				warn: 'bg-warn text-warn-foreground hover:bg-warn/90',
				danger: 'bg-rose-600 text-gray-100 hover:bg-rose-600/90',
				outline:
					'border border-input bg-background hover:bg-accent hover:text-accent-foreground data-[state=active]:bg-accent data-[state=active]:text-accent-foreground',
				secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
				ghost:
					'hover:bg-accent hover:text-accent-foreground data-[state=active]:bg-accent data-[state=active]:text-accent-foreground',
				link: 'text-primary underline-offset-4 hover:underline',
			},
			size: {
				default: 'h-10 px-3 py-2',
				sm: 'h-8 rounded-sm px-2.5',
				xxs: 'h-5 rounded-sm px-1 text-xs',
				xs: 'h-6 rounded-sm px-1.5',
				lg: 'h-10 rounded-sm px-6',
				icon: 'h-10 w-10',
				'icon-sm': 'h-6 w-6',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button: React.FC<ButtonProps> = ({ className, variant, size, asChild = false, ...props }) => {
	const Comp = asChild ? Slot : 'button';
	return <Comp className={cn(buttonVariants({ variant, size, className }))} {...props} />;
};

Button.displayName = 'Button';

interface ButtonGroupProps {
	className?: string;
	orientation?: 'horizontal' | 'vertical';
	children: React.ReactElement<ButtonProps>[];
}
const ButtonGroup = ({ className, orientation = 'horizontal', children }: ButtonGroupProps) => {
	const totalButtons = React.Children.count(children);
	const isHorizontal = orientation === 'horizontal';
	const isVertical = orientation === 'vertical';

	return (
		<div
			className={cn(
				'flex',
				{
					'flex-col': isVertical,
					'w-fit': isVertical,
				},
				className,
			)}
		>
			{React.Children.map(children, (child, index) => {
				const isFirst = index === 0;
				const isLast = index === totalButtons - 1;

				return React.cloneElement(child, {
					className: cn(
						{
							'rounded-l-none': isHorizontal && !isFirst,
							'rounded-r-none': isHorizontal && !isLast,
							'border-l-0': isHorizontal && !isFirst,

							'rounded-t-none': isVertical && !isFirst,
							'rounded-b-none': isVertical && !isLast,
							'border-t-0': isVertical && !isFirst,
						},
						child.props.className,
					),
				});
			})}
		</div>
	);
};

export { Button, buttonVariants, ButtonGroup };
