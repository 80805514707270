import { useSuspenseQuery } from '@tanstack/react-query';
import { Outlet, createFileRoute } from '@tanstack/react-router';
import { redirect } from '@tanstack/react-router';
import { useDocumentTitle } from '@uidotdev/usehooks';
import { getCreativeQueryOptions } from '#app/queries/creative.js';
import CreativeMenu from './components/CreativeMenu';
import CreativeNav from './components/CreativeNav';
import { CreativeProvider } from './contexts';

export const Route = createFileRoute('/_dashboard/campaigns/$campaignId/creatives/$creativeId')({
	component: RouteComponent,
	staleTime: 0,
	loader: async ({ params, context, preload }) => {
		const creative = await context.queryClient.fetchQuery(
			getCreativeQueryOptions(params.creativeId),
		);

		const isInTab = /\/campaigns\/[^/]+\/creatives\/crea_[^/]+\/(?:brief|assets|attributes)/g.test(
			location.pathname,
		);

		if (!isInTab && !preload) {
			throw redirect({
				from: '/campaigns/$campaignId/creatives/$creativeId',
				to: '/campaigns/$campaignId/creatives/$creativeId/assets',
				params,
			});
		}

		return creative;
	},
});

function RouteComponent() {
	const { creativeId } = Route.useParams();

	const { data: creative } = useSuspenseQuery(getCreativeQueryOptions(creativeId));

	useDocumentTitle(`AdFrame - ${creative?.name}`);

	return (
		<>
			<CreativeProvider value={creative}>
				<div className='relative'>
					<div className='sticky top-0 z-10'>
						<div className='flex flex-row space-x-3 border-border border-b bg-background py-2 pl-2'>
							<CreativeNav creative={creative} />
							<CreativeMenu creative={creative} />
						</div>
					</div>
					<div>
						<Outlet />
					</div>
				</div>
			</CreativeProvider>
		</>
	);
}
